import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import * as Styles from '../styles/components/youtubePlayer.module.css';

const player = (props) =>
{
    const [isClicked, setClicked] = useState(false);
    const { youtubeId } = props;
    return (
        <StaticQuery
            query= {graphql`
        query
        {
            thumbnail: file(relativePath: {eq: "youtubeThumbnail.jpg"}) 
            {
                childImageSharp {
                    fluid(maxWidth: 610)
                    {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
        `}
            render={(data) => <div className={Styles.youtubePlayer}>
                {!isClicked ? <div className={Styles.youtubePlayer} onClick={() => setClicked(true)}>
                    <picture>
                        <source type="image/webp" srcSet={data.thumbnail.childImageSharp.fluid.srcSetWebp} sizes={data.thumbnail.childImageSharp.fluid.sizes}></source>
                        <source srcSet={data.thumbnail.childImageSharp.fluid.srcSet} sizes={data.thumbnail.childImageSharp.fluid.sizes}></source>
                        <img loading="lazy" srcSet={data.thumbnail.childImageSharp.fluid.srcSet} sizes={data.thumbnail.childImageSharp.fluid.sizes}></img>
                    </picture>
                    <button className={`${Styles.ytpLargePlayButton} ${Styles.ytpButton}`} aria-label="Play">
                        <svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">
                            <path className={Styles.ytpLargePlayButtonBg} d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#212121" fillOpacity="0.8"></path>
                            <path d="M 45,24 27,14 27,34" fill="#fff"></path>
                        </svg>
                    </button>
                </div>
                    : <iframe width="560" height="315" src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1&rel=0`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> }

            </div>}
        />

    );
};

export default player;
