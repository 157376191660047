import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import Player from '../components/youtubePlayer';
import * as Styles from '../styles/pages/index.module.scss';
import linkExample from '../images/link_example.svg';
import amazonFlags from '../images/amazon_flags.svg';
import companyLogos from '../images/company_logos.svg';
import Heading from '../components/heading';
import Pricing from '../components/pricing';
import RoundButton from '../components/roundedButton';
import SmartRedirect from '../images/smart_redirect.svg';
import SearchTerm from '../images/search_term.svg';
import SEO from '../components/seo';

const Index = ({ data }) => (
    <Layout location={'home'} title={'asd'}>
        <SEO
            title="Geo Linking Made Simple"
            meta={[
                {
                    name: 'keywords',
                    content: 'Affiliate Links Marketing Link Shortening',
                },
            ]}
        />
        <div style={ { background: 'linear-gradient(90deg,#4097e9,#51dab8)' }}>
            <div>
                <div id="1" className={`${Styles.container} ${Styles.main}`}>
                    <div className={Styles.title}>
                        <h1>
                            Link Easier With Locally Links
                        </h1>
                        <p>
                            Built for creators, by a creator. A reliable and simple way to make more money with localised links for Amazon and more!
                        </p>
                    </div>
                    <div className={Styles.video}>
                        <Player youtubeId="8J8JwCEj198"/>
                    </div>
                </div>
            </div>

            {/* Waves SVG */}
            <div style={{ marginTop: '-6rem', display: 'flex' }}>
                <svg viewBox="0 0 1428 174" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g transform="translate(-2.000000, 44.000000)" fill="#FFFFFF" fillRule="nonzero">
                            <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
                            <path d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z" opacity="0.100000001"></path>
                            <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" id="Path-4" opacity="0.200000003"></path>
                        </g>
                        <g transform="translate(-4.000000, 76.000000)" fill="#FFFFFF" fillRule="nonzero">
                            <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"></path>
                        </g>
                    </g>
                </svg>
            </div>

        </div>
        {/* <div id="features"></div> */}
        {/* </div>  */}
        {/* <div className={Styles.heading}>
                <h2 >Locally Links</h2>
            </div> */}
        <div className={`${Styles.container} ${Styles.narrow}`}>
            <Heading id="features" size='h2' text="Locally Links"/>
            <div className={Styles.twoWide}>
                <div className={Styles.child}>
                    <h2>Why use Locally?</h2>
                    <p>
                        Why use 10 links when you can just use 1? Locally lets you create links that direct the clicker to the right link for them. Build links for any country with just a few clicks. When using Locally for Amazon Associates, you can earn more revenue AND clean up your descriptions.
                    </p>
                </div>
                <div className={Styles.child}>
                    <img src={linkExample} height="47" width="150" loading="lazy" alt="Link Shortening"/>
                </div>
            </div>
            <div className={`${Styles.twoWide} ${Styles.reverse}`}>
                <div className={Styles.child}>
                    <img src={amazonFlags} height="25" width="48" loading="lazy" alt="Global Redirect"/>
                </div>
                <div className={Styles.child}>
                    <h2>Amazon Affiliate?</h2>
                    <p>Earn extra revenue by including more locations in a single link! With Locally, you can add as many countries as you have affiliate tags for and publish just one link that takes the user to the right site for them.</p>
                </div>
            </div>
            <div className={`${Styles.twoWide}`}>
                <div className={`${Styles.child}`}>
                    <h2>Smart Feature - Fallback Search Term</h2>
                    <p>If the linked product page cannot be found in a given region then the fallback search term allows a search result page to be shown instead. This increases the chances that a visitor will still be able to find the product they’re after and make a sale from your link.</p>
                </div>
                <div className={`${Styles.child}`}>
                    <img src={SearchTerm} height="23" width="50" loading="lazy" alt="Affiliate Links"/>
                </div>
            </div>
            <div className={`${Styles.twoWide} ${Styles.reverse}`}>
                <div className={`${Styles.child}`}>
                    <img src={SmartRedirect} height="75" width="139" loading="lazy" alt="Maximise Affiliate Revenue"/>
                </div>
                <div className={`${Styles.child}`}>
                    <h2>Smart Redirect</h2>
                    <p>Visitors from countries that are not directly supported by Amazon will be redirected to their most local Amazon site when they use a link. This means the visitor is more likely to be able to purchase a product and have it delivered easily meaning better conversion rate for you.</p>
                </div>
            </div>

            <div className={Styles.twoWide}>
                <div className={Styles.child}>
                    <h2>It&apos;s not just for Amazon</h2>
                    <p>Are you a business who sells their products internationally through multiple distributors? Put any links in for any countries you need. Are you an affiliate to multiple services that only serve specific countries? Locally works for you.</p>
                </div>
                <div className={Styles.child}>
                    <img src={companyLogos} loading="lazy" alt="Amazon Associates Links"/>
                </div>
            </div>
        </div>
        <hr></hr>
        <div className={Styles.container}>
            <Heading size='h3' text='Some of the Creators Already Using Locally'/>
            <div className={Styles.threeWide}>
                <div className={Styles.child}>
                    <a href="https://www.youtube.com/c/ClickTechUK" target="_blank" rel="noreferrer">
                        <h3>ClickTech</h3>
                        <Img className={Styles.photo} fluid={data.clicktech.childImageSharp.fluid}/>
                        <q>
                            <cite>
                                Using Locally, I've seen a marked rise in affiliate revenue thanks to being able to open up to new territories without worrying about spamming my descriptions with multiple links.  The one link solution is a must for any content creator to effectively diversify their income.  Locally comes Highly Recommended!
                            </cite>
                        </q>
                    </a>
                </div>
                <div className={Styles.child}>
                    <a href="https://www.youtube.com/c/pccentric" target="_blank" rel="noreferrer">
                        <h3>PC Centric</h3>
                        <Img className={Styles.photo} fluid={data.pccentric.childImageSharp.fluid}/>
                        <q>
                            <cite>
                                After being a little sceptical of the difference global linking could make, I have to say I'm nothing but impressed. The additional revenue streams, in addition to detailed link tracking and analytics; makes Locally a fantastic service that's easy to recommend.
                            </cite>
                        </q>

                    </a>
                </div> <div className={Styles.child}>
                    <a href="https://www.youtube.com/c/totallydubbedhd" target="_blank" rel="noreferrer">
                        <h3>TotallyDubbedHD</h3>
                        <Img className={Styles.photo} fluid={data.totallydubbed.childImageSharp.fluid}/>
                        <q>
                            <cite>
                                Truthfully, in my years of using different platforms, Locally is the one that I’d actively recommend to friends and family, fellow content creators, business owners and publications. It’s a game changer and has already proved to be one of the best tools I’ve ever used.
                            </cite>
                        </q>

                    </a>
                </div>
            </div>
        </div>
        <hr></hr>
        <div className={Styles.container}>
            <Heading size='h3' text="Why Use Locally?"/>
            <Link to="/cschris">
                <div className={Styles.twoWide}>
                    <div className={Styles.child}>
                        <h2>Case Study - Chris, TotallyDubbedHD</h2>
                        <p>Chris, a long time tech YouTuber, saw a 110% increase in Amazon Associates revenue after replacing his existing links with Locally Links. Read more about his experiences and thoughts on the platform here.</p>
                    </div>
                    <div className={Styles.child}>
                        <Img fluid={data.totallydubbedwebsite.childImageSharp.fluid} />
                    </div>
                </div>
            </Link>
        </div>
        <div className={Styles.gray}>
            <div className={Styles.container}>
                <Heading id='pricing' size='h2' text='Pricing'/>
                <Pricing />
            </div>

        </div>

    </Layout>
);

export const query = graphql`
  query {
    totallydubbed: file(relativePath: {eq: "totallydubbedhd.jpg"}) {
        childImageSharp {
            fluid(maxHeight: 400, quality : 90) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pccentric: file(relativePath: {eq: "marcus.jpg"}) {
        childImageSharp {
            fluid(maxHeight: 400, quality : 90) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    clicktech: file(relativePath: {eq: "kev.png"}) {
        childImageSharp {
            fluid(maxWidth:960, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    totallydubbedwebsite: file(relativePath: {eq: "chrisBanner.jpg"}) {
        childImageSharp {
            fluid(maxWidth:960, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
  }
`;

export default Index;
